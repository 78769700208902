<template>
  <div class="mm-list-products-view">
    <ClipLoader v-if="loading.value" />
    <template v-else>
      <div
        v-if="isVisibleTabs"
        class="mm-list-products-view__tabs"
      >
        <Tabs
          :model-value="currentTab"
          @update:model-value="setCurrentTab"
        >
          <Tab
            v-for="(tab, index) in (tabs || [])"
            :key="tab?.value || index"
            v-slot="{ isActive }"
            :value="tab?.value"
          >
            {{ tab?.label }} <span
              class="mm-list-products-view__tabs__item-count"
              :class="{ 'active': !isActive }"
            >{{ tab?.count }}</span>
          </Tab>
        </Tabs>
      </div>

      <ClientOnly>
        <ProductsView
          :key="updateVersion"
          :initial-view-data="(selectedTabAnalogsOrSimilars && EProductView.Table) || undefined"
          hide-products-count
          disable-go-to-product
          is-erp
          :title="productsViewTitle"
          :category-id="categoryId"
          :fias-id="basesService?.getCurrentRegionFiasId()"
          :basis-id="ociBasket?.getBasisId()"
          :mtr-code="mtrCodeForProductsRequest"
          is-sticky-thead
          lazy
          class="mm-oci-basket-drawer-list-products__view"
          :class="{ 'no-tabs' : !isVisibleTabs }"
          :items="sortedProducts"
          :hide-filters="selectedTabAnalogsOrSimilars"
          oci-mode
          :related="selectedTabAnalogsOrSimilars"
          :client-id="clientId || undefined"
          :search-text="ociBasketDrawerManager?.searchTextRequest.value"
          :saved-filters="ociBasketDrawerManager?.getFiltersByCurrentTab()"
          :default-sort="ociBasketDrawerManager?.getSortByCurrentTab()"
          :products-fetch-key="productsFetchKey"
          :filters-fetch-key="filtersFetchKey"
          :loading-products="loadingRelatedProducts.value"
          @click-product="onClickProduct"
          @sort-changed="onSortChanged"
          @search-clear="onClearSearch"
          @update-products-data="onUpdateProductsDataCount"
          @filters-changed="onFiltersChanged"
        >
          <template #subHeader>
            <MultiCategory
              v-if="isMultiselectCategory"
              v-model="category"
              class="mm-list-products-view__content-sub-header"
              :categories="categories"
              label="Показаны товары из категории:"
              @update:model-value="onSelectCategory"
            />
            <SearchTextField
              v-model="searchText"
              placeholder="Введите название или артикул товара"
              class="mm-list-products-view__search"
              @search="onSearch"
              @clear="onClearSearch"
            />
          </template>

          <template #footerText>
            <BasketErpListProductsTableFooter
              :current-tab="currentTab"
              :is-analog-tab="!!getAnalogsCount()"
              :is-similar-tab="!!getSimilarCount()"
              @click-analogs="onClickAnalogs"
              @click-category="onClickCategory"
              @click-similar="onClickSimilar"
            />
          </template>
        </ProductsView>
      </ClientOnly>
    </template>
  </div>
</template>

<script lang="ts" setup>
import Tabs from 'shared/components/tabs/Tabs.vue';
import Tab from 'shared/components/tabs/Tab.vue';
import Loader from 'shared/utils/loaderHelper.util';
import { IProduct } from 'shared/models/product.model';
import ClipLoader from 'shared/components/ClipLoader.vue';
import { EErpBasketListProductsView } from 'mp-enums/basket/erpBasketListProductsView.enum';
import { OciBasketDrawerManager } from 'oci-services/basket/ociBasketDrawerManager.service';
import { ITab } from 'shared/models/tabs.model';
import { erpBasketListProductsTitleMap, ociBasketListProductsTitleMap } from 'constants/basket/erpBasketListProducts.const';
import { ICategory } from 'shared/models/category.model';
import { PimCatalogService } from 'mp-services/api/pimCatalogApi.service';
import BasesService from 'mp-services/basesManager.service';
import Notificator from 'shared/services/notificator.service';
import { AbortControllerUtil } from 'shared/utils/abortController.util';
import { expandingCategoriesChildren } from 'shared/utils/expandingCategoriesChildren.util';
import QueryParamsService from 'shared/services/queryParams.service';
import { useOciManager } from 'oci-composables/useOciManager';
import ProductsView from 'mp-components/ProductsView.vue';
import { useUpdate } from 'shared/composables/useUpdate';
import { ESortProductDirections } from 'shared/enums/sortDirections.enum';
import { orderBy } from 'lodash-es';
import { EProductView } from 'mp-enums/productView.enum';
import BasketErpListProductsTableFooter from 'mp-components/basket-erp/basket-erp-products-drawer/BasketErpListProductsTableFooter.vue';
import SearchTextField from 'shared/components/SearchTextField.vue';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { FilterUtils } from 'shared/utils/filterHelper.util';
import { EProductFilterId } from 'enums/productFilterId.enum';
import { OciBasket } from 'oci-services/basket/ociBasket.service';
import { EFetchKey } from 'enums/fetchKey.enum';
import { IFilterItem } from 'shared/models/filters.model';

const ociBasket = inject<OciBasket>(OciBasket.getServiceName());
const ociBasketDrawerManager = inject<OciBasketDrawerManager>(OciBasketDrawerManager.getServiceName());
const basesService = inject<BasesService>(BasesService.getServiceName());
const queryParamsService = inject<QueryParamsService>(QueryParamsService.getServiceName());

const { clientId } = await useOciManager();
const route = useRoute();

const sentryName = 'OciBasketDrawerListProductsView';

const getTitleCountMap = new Map<EErpBasketListProductsView, () => number>([
  [EErpBasketListProductsView.Analogs, getAnalogsCount],
  [EErpBasketListProductsView.SimilarProducts, getSimilarCount],
  [EErpBasketListProductsView.CategorySearch, getCategorySearchCount],
  [EErpBasketListProductsView.NeedSuitableItems, getNeedSuitableItemsCount],
]);

const analogs = ref<Array<IProduct>>([]);
const similars = ref<Array<IProduct>>([]);
const needSuitableItemsCount = ref<number>(0);
const categoryCount = ref(0);
const category = ref<ICategory>();
const categories = ref<Array<ICategory>>([]);
const sort = ref<ESortProductDirections>(ociBasketDrawerManager?.getSortByCurrentTab() ?? ESortProductDirections.Default);
const searchText = ref<string>(ociBasketDrawerManager?.searchTextRequest.value ?? '');

const loading = Loader.getReactiveInstance();
const loadingRelatedProducts = Loader.getReactiveInstance();

const { updateVersion, incrementVersion } = useUpdate();

const categoryId = computed(() => category.value?.id);

const isVisibleTabs = computed(
  () => ociBasketDrawerManager?.isNeedSuitableItems.value || !!(getSimilarCount() || getAnalogsCount()),
);

const currentTab = computed(() => ociBasketDrawerManager?.productsViewTab.value);

const tabs = computed<Array<ITab>>(() =>
  Array.from(ociBasketDrawerManager?.isNeedSuitableItems.value ? ociBasketListProductsTitleMap : erpBasketListProductsTitleMap)
    .filter(filterTab)
    .map(mapTab),
);

const mtrCodeForProductsRequest = computed(() => isNeedSuitableView(currentTab.value) ? ociBasketDrawerManager?.preErpPosition.value?.mtrCode : undefined);

const productsViewTitle = computed(() => {
  if (!currentTab.value) {
    return '';
  }

  if (currentTab.value === EErpBasketListProductsView.NeedSuitableItems) {
    return 'Товары подходящие под потребность';
  }

  return [
    EErpBasketListProductsView.Analogs,
    EErpBasketListProductsView.SimilarProducts,
  ].includes(currentTab.value)
    ? ''
    : `Товары из категории «${category.value?.title}»`;
});

const selectedTabAnalogsOrSimilars = computed(() => currentTab.value && [
    EErpBasketListProductsView.Analogs,
    EErpBasketListProductsView.SimilarProducts,
  ].includes(currentTab.value),
);

const productsForDisplay = computed(
  () => currentTab.value === EErpBasketListProductsView.Analogs
    ? analogs.value
    : currentTab.value === EErpBasketListProductsView.SimilarProducts
      ? similars.value
      : undefined,
);

const sortedProducts = computed(
  () => productsForDisplay.value && (!sort.value || sort.value === ESortProductDirections.Default
    ? productsForDisplay.value
    : orderBy(productsForDisplay.value || [], [getProductSortValue], [getSortOrder()])) || undefined,
);

const isMultiselectCategory = computed(
  () => !currentTab.value
    && !ociBasketDrawerManager?.preErpPosition.value?.categoryId
    && categories.value?.length,
);

const analogsIsShown = computed(
  () => currentTab.value === EErpBasketListProductsView.Analogs,
);

const similarsIsShown = computed(
  () => currentTab.value === EErpBasketListProductsView.SimilarProducts,
);

const fetchKey = computed(
  () => {
    if (analogsIsShown.value) {
      return 'oci-analogs';
    }

    if (similarsIsShown.value) {
      return 'oci-similars';
    }

    return 'oci-categorySearch';
  },
);

const productsFetchKey = computed(
  () => `${EFetchKey.Products}-${fetchKey.value}`,
);

const filtersFetchKey = computed(
  () => `${EFetchKey.Filters}-${fetchKey.value}`,
);

function getAnalogsCount(): number {
  const savedCount = ociBasketDrawerManager?.getItemsCountByTab(EErpBasketListProductsView.Analogs);

  return (getIsModels()
    && (savedCount ? savedCount : analogs.value?.length)
  ) || 0;
}

function getSimilarCount(): number {
  const savedCount = ociBasketDrawerManager?.getItemsCountByTab(EErpBasketListProductsView.SimilarProducts);

  return (getIsModels()
    && (savedCount ? savedCount : similars.value?.length)
  ) || 0;
}

function getNeedSuitableItemsCount(): number {
  return needSuitableItemsCount.value || 0;
}

function onClickProduct(product: IProduct): void {
  const { fullPath } = route;
  window.history.pushState(null, '', fullPath || '');
  ociBasketDrawerManager?.goToProduct(product);
}

function getIsModels(): boolean {
  return !!ociBasketDrawerManager?.preErpPosition.value?.modelIds?.length;
}

function filterTab([erpProductViewType]: [EErpBasketListProductsView, string]): boolean {
  return isNeedSuitableView(erpProductViewType) || isCategorySearchView(erpProductViewType) || !!getTitleCount(erpProductViewType);
}

function isNeedSuitableView(view = currentTab.value): boolean {
  return view === EErpBasketListProductsView.NeedSuitableItems;
}

function isCategorySearchView(view = currentTab.value): boolean {
  return view === EErpBasketListProductsView.CategorySearch;
}

function getTitleCount(productView: EErpBasketListProductsView): number {
  const countFn = getTitleCountMap.get(productView);
  return countFn ? countFn() ?? 0 : 0;
}

function getCategorySearchCount(): number {
  return categoryCount.value;
}

function mapTab([value, label]: [EErpBasketListProductsView, string]): ITab {
  return {
    value,
    label,
    count: getTitleCount(value),
  };
}

function getProductSortValue(product: IProduct): string | number {
  return [
    ESortProductDirections.AlphaAsc,
    ESortProductDirections.AlphaDsc,
  ].includes(sort.value)
    ? product.name
    : product.priceInfo.min;
}

function getSortOrder(): string {
  const sortOrder = sort.value?.split('-')?.[1] || '';
  return sortOrder === 'dsc' ? 'desc' : sortOrder;
}

function onSortChanged(newSort: ESortProductDirections): void {
  sort.value = newSort;

  if (ociBasketDrawerManager) {
    ociBasketDrawerManager.setSortByCurrentTab(newSort);
  }
}

function onClickAnalogs(): void {
  setCurrentTab(EErpBasketListProductsView.Analogs);
}

function onClickSimilar(): void {
  setCurrentTab(EErpBasketListProductsView.SimilarProducts);
}

function onClickCategory(): void {
  setCurrentTab(EErpBasketListProductsView.CategorySearch);
}

async function loadCategory(categoryId: string): Promise<void> {
  try {
    category.value = (await PimCatalogService.getCategoryById(
      categoryId,
      6,
      basesService?.getCurrentRegionFiasId(),
      undefined,
      true,
      true,
      clientId.value || undefined,
      undefined,
      undefined,
      true,
    )
    )?.category;
  } catch (error) {
    if (AbortControllerUtil.isRequestAborted(error)) {
      return await loadCategory(categoryId);
    }

    clientSentry.captureServiceException(
      error,
      sentryName,
      undefined,
      {
        extra: {
          categoryId,
          regionFiasId: basesService?.getCurrentRegionFiasId(),
        },
      },
    );
    Notificator.showDetachedNotification('Ошибка при загрузке категории');
    ociBasketDrawerManager?.closeDrawer();
  }
}

async function loadProductRelated(): Promise<void> {
  loadingRelatedProducts.activate();
  try {
    const filtersForCurrentTab = ociBasketDrawerManager?.getFiltersByCurrentTab() ?? [];

    const directDelivery = FilterUtils.getFilterValueById<boolean>(
      filtersForCurrentTab,
      EProductFilterId.DirectDelivery,
    );

    const available = FilterUtils.getFilterValueById<boolean>(
      filtersForCurrentTab,
      EProductFilterId.Available,
    );

    const catalogId = FilterUtils.getFilterValueById<number>(
      filtersForCurrentTab,
      EProductFilterId.CatalogId,
    );

    const response = await PimCatalogService.getProductRelated(
      ociBasketDrawerManager?.preErpPosition.value?.modelIds || [],
      basesService?.getCurrentRegionFiasId(),
      undefined,
      clientId.value || undefined,
      undefined,
      true,
      true,
      ociBasket?.getBasisId(),
      undefined,
      directDelivery,
      available,
      catalogId,
    );

    analogs.value = response.substitutions;
    similars.value = response.similars;

    let itemsCount: number | undefined;
    if (analogsIsShown.value) {
      itemsCount = analogs.value.length;
    } else if (similarsIsShown.value) {
      itemsCount = similars.value.length;
    }
    if (currentTab.value && itemsCount) {
      ociBasketDrawerManager?.setItemsCountByTab(currentTab.value, itemsCount);
    }
  } catch (error) {
    if (AbortControllerUtil.isRequestAborted(error)) {
      return await loadProductRelated();
    }

    clientSentry.captureServiceException(
      error,
      sentryName,
      undefined,
      {
        extra: {
          modelIds: ociBasketDrawerManager?.preErpPosition.value?.modelIds || 'empty',
          regionFiasId: basesService?.getCurrentRegionFiasId(),
        },
      },
    );
    Notificator.showDetachedNotification('Ошибка при загрузке аналогов и похожих товаров');
  } finally {
    loadingRelatedProducts.deactivate();
  }
}

async function loadCategoriesCount(): Promise<void> {
  if (!ociBasketDrawerManager?.preErpPosition.value?.categoryId) {
    return;
  }

  try {
    categoryCount.value = (await PimCatalogService.getProducts({
      categoryId: ociBasketDrawerManager?.preErpPosition.value?.categoryId,
      fiasId: basesService?.getCurrentRegionFiasId(),
      clientId: clientId.value,
      useMtrPrice: true,
      useAllowedCategory: true,
    })).count ?? 0;
  } catch (error) {
    if (AbortControllerUtil.isRequestAborted(error)) {
      return await loadCategoriesCount();
    }

    clientSentry.captureServiceException(
      error,
      sentryName,
      undefined,
      {
        extra: {
          categoryId: ociBasketDrawerManager.preErpPosition.value?.categoryId || 'empty',
          regionFiasId: basesService?.getCurrentRegionFiasId(),
        },
      },
    );
    Notificator.showDetachedNotification('Ошибка при загрузке данных');
  }
}

function setCurrentTab(tab: EErpBasketListProductsView): void {
  incrementVersion();
  sort.value = ESortProductDirections.PriceAsc;
  if (ociBasketDrawerManager) {
    ociBasketDrawerManager.productsViewTab.value = tab;
  }
  queryParamsService?.clear();
}

function initView(): void {
  if (ociBasketDrawerManager?.productsViewTab.value) {
    return;
  }

  if (ociBasketDrawerManager?.isNeedSuitableItems.value) {
    setCurrentTab(EErpBasketListProductsView.NeedSuitableItems);
  } else if (getAnalogsCount()) {
    setCurrentTab(EErpBasketListProductsView.Analogs);
  } else if (getSimilarCount()) {
    setCurrentTab(EErpBasketListProductsView.SimilarProducts);
  } else if (ociBasketDrawerManager?.oldProduct.value?.categoryId) {
    setCurrentTab(EErpBasketListProductsView.CategorySearch);
  }
}

async function loadCategories(): Promise<void> {
  try {
    const categoriesResponse = await PimCatalogService.getCategories(
      6,
      basesService?.getCurrentRegionFiasId(),
      undefined,
      undefined,
      undefined,
      true,
      true,
      clientId.value || undefined,
      undefined,
      true,
    );

    categories.value = expandingCategoriesChildren(categoriesResponse?.category?.subcategory || []);

    if (ociBasketDrawerManager?.savedCategoryId.value) {
      category.value = categories.value?.find(
        (category) => category.id === ociBasketDrawerManager?.savedCategoryId.value,
      ) ?? undefined;
    }
  } catch (error) {
    if (AbortControllerUtil.isRequestAborted(error)) {
      return await loadCategories();
    }
    Notificator.showDetachedNotification('Ошибка при загрузке категорий');
    clientSentry.captureServiceException(
      error,
      sentryName,
      undefined,
      {
        extra: {
          regionFiasId: basesService?.getCurrentRegionFiasId(),
        },
      },
    );
  }
}

async function init(): Promise<void> {
  loading.activate();
  try {
    const preErpPositionCategoryId = ociBasketDrawerManager?.preErpPosition.value?.categoryId
      || ociBasketDrawerManager?.savedCategoryId.value;
    if (preErpPositionCategoryId) {
      await loadCategory(preErpPositionCategoryId);
    }

    if (getIsModels() && !ociBasketDrawerManager?.isNeedSuitableItems.value) {
      await loadProductRelated();
    }

    await loadCategoriesCount();

    initView();

    if (!currentTab.value) {
      await loadCategories();
    }
  } finally {
    loading.deactivate();
  }
}

function onSearch(): void {
  if (!ociBasketDrawerManager) {
    return;
  }

  ociBasketDrawerManager.searchTextRequest.value = searchText.value;
}

function onClearSearch() {
  queryParamsService?.clearQueryParam('search');
  searchText.value = '';

  if (ociBasketDrawerManager) {
    ociBasketDrawerManager.searchTextRequest.value = '';
  }
}

function onUpdateProductsDataCount(count: number): void {
  if (currentTab.value === EErpBasketListProductsView.CategorySearch) {
    categoryCount.value = count;
    return;
  }

  if (currentTab.value === EErpBasketListProductsView.NeedSuitableItems) {
    needSuitableItemsCount.value = count;
  }
}

async function onFiltersChanged(filters: Array<IFilterItem>): Promise<void> {
  if (!ociBasketDrawerManager) {
    return;
  }

  ociBasketDrawerManager.setFiltersByCurrentTab(filters);

  if (loadingRelatedProducts.value || !selectedTabAnalogsOrSimilars.value) {
    return;
  }

  await loadProductRelated();
}

function onSelectCategory(selectedValue: ICategory | null) {
  if (selectedValue && ociBasketDrawerManager) {
    ociBasketDrawerManager.savedCategoryId.value = selectedValue.id;
  }
  onClearSearch();
}

onMounted(async () => await init());
</script>

<style lang="scss" scoped>
@import 'styles/base/common/variables';

$root: '.mm-list-products-view';
$content: '.product-view';
$list: '.list';

#{$root} {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  :deep(.list__container) {
    min-width: 100%;
  }

  :deep(.mm-table) {
    border-collapse: separate;
    border-spacing: 0;

    .mm-table__head {
      box-shadow: inset 0 -1px 0 0 $gray-200;
    }

    .mm-table--theme-default:not(.mm-table--children) .mm-table__body > tr:not(.mm-table__row--show-more):not(.mm-table__row--empty):hover {
      z-index: unset;
    }
  }

  &__tabs {
    padding: 0 40px;

    &__item {
      &-count {
        margin-left: 4px;

        &.active {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $light-green;
        }
      }
    }
  }

  &__content-sub-header {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-black;
  }

  :deep(#{$content}) {
    max-width: 100%;
    margin: 0;
    flex: 1;
    overflow-y: auto;

    &.no-tabs {
      .product-view__wrapper {
        .product-view__header {
          padding-top: 0 !important;
        }
      }
    }

    .product-view__header {
      .multi-category--wrapper.mm-list-products-view__content-sub-header {
        .multi-category {
          margin-bottom: unset !important;
        }
      }

      .headline {
        h1 {
          font-size: 20px !important;
          font-weight: 500;
        }

        margin: 0;
      }
    }

    .product-view__header,
    .controls {
      padding-left: 40px !important;
      padding-right: 40px !important;
      padding-top: 24px !important;
    }

    &.erp {
      &:has(.filters) {
        .product-view__header,
        .controls {
          padding-left: 40px !important;
          padding-right: 40px !important;
          padding-top: 24px !important;
        }
      }
    }

    &.no-tabs {
      .product-view__header {
        &::before {
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          top: 0;
          left: 40px;
          right: 40px;
          background-color: $gray-200;
        }
      }
    }

    .product-view__header {
      margin: 0;
      padding: 40px 16px 0;
      background: $white;

      .mm-list-products-view__content-sub-header {
        display: none;
        margin-top: 0;
      }

      & + .controls {
        padding: 40px 16px;
        top: 76px;
      }

      &:has(.multi-category--wrapper.mm-list-products-view__content-sub-header) {
        .headline {
          display: none;
        }

        padding-top: 4px !important;
      }
    }

    .controls {
      margin: 0;
      padding-top: 0;
      padding-bottom: 24px;
      background: $white;

      &:not(:has(.filters)) {
        padding-bottom: 32px;
      }

      & + .list {
        margin-top: 0;

        .mm-table {
          .mm-table__head {
            top: 62px;
          }
        }
      }
    }
  }

  &.is-group {
    :deep(#{$content}) {
      .product-view__header {
        padding: 0px 16px 0 !important;
        margin-top: -20px !important;

        .headline {
          display: none;
        }

        .mm-list-products-view__content-sub-header {
          display: block;

          .multi-category {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  :deep(#{$list}) {
    padding: 0;
    margin-top: 24px;
    padding-bottom: 24px !important;
  }

  &-table {
    flex: 1;
    overflow-y: auto;

    &__header {
      padding: 0 16px;
      margin-top: 30px;

      h2 {
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
        color: $text-black;
        margin: 0 0 16px;
      }

      p {
        padding: 0;
        margin: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $text-black;
      }
    }
  }

  &__search {
    width: 100% !important;
    margin: 20px 0 0 0;

    @media (max-width: 768px) {
      :deep(input) {
        height: 28px !important;
        font-size: 12px !important;
      }

      :deep(.icon) {
        width: 20px;
        height: 20px;
      }

      :deep(.mm-search-text-field_after__container) {
        top: 0;
        height: 28px;
      }
    }
  }

  :deep(.empty-banner) {
    margin: 8px auto 40px auto;
    max-width: calc(100% - 32px);

    .banner__title {
      font-weight: 500;
    }

    @media only screen and (min-width: 320px) and (max-width: 767px) {
      padding-top: 112px;
      background-position: right top;
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-list-products-view__tabs {
    padding: 0 20px;
  }

  :deep(#{$content}) {
    .multi-category--wrapper.mm-list-products-view__content-sub-header {
      margin-top: 12px !important;
    }
  }

  .mm-list-products-view-table__header {
    padding: 0 20px;

    h2 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .mm-list-products-view {
    :deep(.list) {
      padding: 0;
    }

    &.is-group {
      :deep(#{$content}) {
        .product-view__header {
          margin-top: -40px !important;
        }
      }
    }
  }

  #{$root} {
    :deep(#{$content}) {
      .controls {
        padding-left: 20px !important;
        padding-right: 20px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .mm-list-products-view {
    :deep(.product-view) {
      .product-view__header {
        padding: 32px 20px 0;
      }

      .controls {
        padding: 12px 20px;

        & + .list {
          padding: 32px 4px 0;
        }
      }
    }
  }
}

</style>
