<template>
  <div
    class="mm-product-view"
    :class="{ 'no-headline' : supplierIsChanging }"
  >
    <ClipLoader v-if="loadingDetails.value" />
    <div v-else>
      <div
        v-if="productDetails"
        class="mm-product-view__content"
      >
        <h3
          id="product_name_modal"
          class="mm-product-view__content__header"
        >
          {{ productDetails?.name }} <span class="mm-product-view__content__header--light">
            {{ productDetails?.mtrCode }}
          </span>
        </h3>

        <ProductActions
          :product-details="productDetails"
          class="mm-product-view__content__actions"
          oci-mode
        />
      </div>

      <div class="mm-product-view__content__wrapper">
        <div class="mm-product-view__content__side left">
          <template v-if="productDetails">
            <div class="mm-product-view__content__side-images">
              <ImageGallery
                :images="productDetails?.images?.length ? productDetails.images : [{ url: '/images/empty-product-images.png' }]"
              />
            </div>

            <div class="mm-product-view__content__side-characteristic visible-large-desktop visible-small-desktop">
              <ProductDetailsInfo :product-details="productDetails" />
            </div>
          </template>
        </div>

        <div class="mm-product-view__content__side right">
          <ClipLoader v-if="loadingOffers.value && !offersData?.items?.length" />

          <template v-else-if="!loadingDetails.value">
            <ProductCalculateInputs
              v-model:productsCount="productsCount"
              v-model:selectedCalcType="selectedCalcType"
              v-model:available-offers="availableOffers"
              v-model:direct-delivery="directDeliveryOffers"
              :hide-select-row="!offersData?.items?.length && !filteredOffers"
              :offers-data="offersData"
              :select-options="selectOptions"
              :unit-name="UnitOkeiHelper.getModelUnitOkeiName(productDetails?.unit)"
              hidden-select-calc-type
              :disabled-available-offers-checkbox="loadingOffers.value"
              :disabled-direct-delivery-checkbox="loadingOffers.value"
              :disabled-input="loadingOffers.value"
              :precision="UnitOkeiHelper.getModelUnitPrecision(productDetails?.unit)"
              :offers-count="productOffers.offersCount.value || 0"
              @update:focus="onUpdateFocusCalculateInputs"
              @reset-count="onResetProductCount"
            />

            <div
              v-if="offersData?.items?.length"
              class="mm-product-view__offers"
            >
              <ProductOffers
                :product-details="productDetails"
                :products-count="productsCount"
                :product-id="productId"
                :is-loading="ociBasketDrawerManager?.loading.value || loadingOffers.value"
                :selected-calc-type="selectedCalcType"
                :loading-add-to-cart="ociBasketDrawerManager?.loading.value"
                :focused-quantity-input="isFocusCalculateInputs"
                is-erp
                show-offer-quantity
                oci-mode
                :sorted-offers-items="productOffers.sortedOffersItems.value || []"
                @add-count="onAddCount"
                @add-to-cart="onAddToCart"
                @reset-count="onResetCount"
              />
            </div>

            <ProductPriceCityInfo
              :product-details="productDetails"
              :user-basis="userBasis"
            />
          </template>
        </div>

        <div class="mm-product-view__content__side-characteristic visible-tablet visible-mobile">
          <ProductDetailsInfo :product-details="productDetails" />
        </div>
      </div>

      <SimilarsAndAnalogs
        :basis-id="userBasis.id"
        :region-id="basesManager?.getCurrentRegionFiasId()"
        :client-id="clientId"
        :okei-code="productDetails?.unit?.okei?.code"
        :model-id="productId"
        has-mtr-code
        oci-mode
        :product-click-fn="onProductClick"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { debounce } from 'lodash-es';
import {
  ICartOfferInfo,
  IProductDetails,
  IRelatedProductClickEvent,
  IResultedOffersBody,
} from 'shared/models/product.model';
import { PimCatalogService } from 'services/api/pimCatalogApi.service';
import Loader from 'shared/utils/loaderHelper.util';
import ClipLoader from 'shared/components/ClipLoader.vue';
import ImageGallery from 'shared/components/gallery/ImageGallery.vue';
import { ISelectOption } from 'shared/models/select.model';
import { PricesApiService } from 'mp-services/api/pricesApi.service';
import BasesService from 'mp-services/basesManager.service';
import { EProductProvideKeys } from 'mp-enums/productProvideKeys.enum';
import ProductPriceCityInfo from 'mp-components/productDetails/ProductPriceCityInfo.vue';
import { IDeliveryBase } from 'shared/models/bases.model';
import ProductOffers from 'mp-components/productDetails/ProductOffers.vue';
import ProductCalculateInputs from 'mp-components/productDetails/ProductCalculateInputs.vue';
import ProductDetailsInfo from 'mp-components/productDetails/ProductDetailsInfo.vue';
import Notificator from 'shared/services/notificator.service';
import { AbortControllerUtil } from 'shared/utils/abortController.util';
import ProductActions from 'mp-components/productDetails/ProductActions.vue';
import { OciBasketDrawerManager } from 'oci-services/basket/ociBasketDrawerManager.service';
import { OciBasket } from 'oci-services/basket/ociBasket.service';
import { useOciManager } from 'oci-composables/useOciManager';
import { PRODUCT_OFFERS_PRICE_WITH_DELIVERY_RESULT } from 'mp-constants/pages/product.const';
import { EClientId } from 'mp-enums/client/clientIdName.enum';
import { IPreOrderItem } from 'oci-models/basket/preOrderItem.model';
import SimilarsAndAnalogs from 'components/similars-and-analogs/SimilarsAndAnalogs.vue';
import { WatchSubscription } from 'shared/utils/watchSubscription';
import useSSRUnsubscribeWatch from 'shared/composables/useSSRUnsubscribeWatch';
import { UnitOkeiHelper } from 'mp-utils/unitOkeiHelper.util';
import { FilterUtils } from 'shared/utils/filterHelper.util';
import { EProductFilterId } from 'mp-enums/productFilterId.enum';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import {useProductOffers } from 'composables/useProductOffers';

const ociBasketDrawerManager = inject<OciBasketDrawerManager>(OciBasketDrawerManager.getServiceName());
const basesManager = inject<BasesService>(BasesService.getServiceName());
const ociBasket = inject<OciBasket>(OciBasket.getServiceName());

const { clientId } = await useOciManager();

const popstateEvent = 'popstate';

const productDetails = ref<IProductDetails>();
const offersData = ref<IResultedOffersBody>();
const productsCount = ref(ociBasketDrawerManager?.maxItems?.value?.toString() || '1');
const selectedCalcType = ref<ISelectOption>(PRODUCT_OFFERS_PRICE_WITH_DELIVERY_RESULT[1]);
const cartOffersState = ref<Array<ICartOfferInfo>>([]);
const selectedRelatedProductId = ref();
const isFocusCalculateInputs = ref(false);
const loadingDetails = Loader.getReactiveInstance();
const loadingOffers = Loader.getReactiveInstance();

provide(EProductProvideKeys.OffersData, offersData);
provide(EProductProvideKeys.CartOfferState, cartOffersState);

const productId = computed<number>(
  () => selectedRelatedProductId.value
    || ociBasketDrawerManager?.product.value?.modelId
    || ociBasketDrawerManager?.product.value?.id,
);
const userBasis = computed<Partial<IDeliveryBase>>(() => basesManager?.currentUserBase);
const watchSubscription = new WatchSubscription();
const supplierIsChanging = computed(() => ociBasketDrawerManager?.changeSupplier.value);

let abortController: AbortController;

const availableOffers = ref(
  FilterUtils.getFilterValueById<boolean>(
    ociBasketDrawerManager?.getFiltersByCurrentTab() ?? [],
    EProductFilterId.Available,
  ) || false,
);
const directDeliveryOffers = ref(
  FilterUtils.getFilterValueById<boolean>(
    ociBasketDrawerManager?.getFiltersByCurrentTab() ?? [],
    EProductFilterId.DirectDelivery,
  ) || false,
);

const filteredOffers = computed(
  () => availableOffers.value || directDeliveryOffers.value,
);

const productOffers = useProductOffers({
  offersData,
  selectedCalcType,
  isErp: true,
  productsCount,
});

async function loadProductDetails(): Promise<void> {
  try {
    productDetails.value = await PimCatalogService.getProductDetailsById(
      productId.value,
      clientId.value as number,
      basesManager?.getCurrentRegionFiasId(),
      ociBasketDrawerManager?.preErpPosition.value?.mtrCode,
      {
        withMtrCode: clientId.value === EClientId.NLMK,
        oci: true,
        okeiCode: UnitOkeiHelper.getUnitOkeiCode(ociBasketDrawerManager?.preErpPosition.value?.unit),
      },
    );
  } catch (error) {
    ociBasketDrawerManager?.closeDrawer();
    throw error;
  }
}

async function loadData(): Promise<void> {
  loadingDetails.activate();
  loadingOffers.activate();

  try {
    await loadProductDetails();
    await onSelectOptionUpdate();
  } catch (error) {
    Notificator.showDetachedNotification('Ошибка при загрузке данных');
    console.error(error);
  } finally {
    loadingDetails.deactivate();
    loadingOffers.deactivate();
  }
}

function onUpdateFocusCalculateInputs(isFocused: boolean): void {
  isFocusCalculateInputs.value = isFocused;
}

async function onSelectOptionUpdate(): Promise<void> {
  if (!+productsCount.value || !productId.value) {
    return;
  }

  loadingOffers.activate();

  try {
    offersData.value = await PricesApiService.calculatePrice({
      productId: productId.value,
      basisId: basesManager?.currentUserBase?.id || undefined,
      quantity: +productsCount.value || 1,
      clientId: clientId.value as number,
      fiasId: basesManager?.getCurrentRegionFiasId(),
      ociMode: true,
      useFixedDelivery: true,
      useQuantity: false,
      useMtrPrice: true,
      useAllowedCategory: true,
      okeiCode: UnitOkeiHelper.getUnitOkeiCode(ociBasketDrawerManager?.preErpPosition.value?.unit),
      available: availableOffers.value || undefined,
      directDelivery: directDeliveryOffers.value || undefined,
    });
  } catch (error) {
    Notificator.showDetachedNotification('Ошибка при загрузке предложений');
    clientSentry.captureComponentException(
      error,
      'OciBasketDrawerProductView',
    );
  } finally {
    loadingOffers.deactivate();
  }

}

function onAddCount(count: number): void {
  productsCount.value = count.toString();
}

async function onAddToCart(cartOfferInfo: ICartOfferInfo): Promise<void> {
  ociBasketDrawerManager?.loading.activate();
  const productReplace = !!ociBasketDrawerManager?.oldProduct.value?.id;

  try {
    const preOrderItem: IPreOrderItem = {
      offerId: cartOfferInfo.offerId,
      quantity: Number(productsCount.value),
      modelId: productId.value,
      erpPositionId: ociBasketDrawerManager?.preErpPosition.value?.id,
    };

    if (productReplace) {
      await ociBasket?.updateProduct(
        ociBasketDrawerManager.oldProduct.value?.id as number,
        preOrderItem,
      );
    } else {
      await ociBasket?.addToBasket(preOrderItem);
    }
    cartOffersState.value.push(cartOfferInfo);
    ociBasketDrawerManager?.loading.deactivate();
    ociBasketDrawerManager?.closeDrawer();
  } catch (error) {
    Notificator.showDetachedNotification(
      productReplace
        ? 'Ошибка при замене товара'
        : 'Ошибка при добавлении товара в корзину',
    );
  } finally {
    ociBasketDrawerManager?.loading.deactivate();
  }
}

function clearCartOffersState() {
  cartOffersState.value = [];
}

function onPopstate(): void {
  if (!supplierIsChanging.value) {
    ociBasketDrawerManager?.goBackToProducts();
  }
}

async function init(): Promise<void> {
  window.addEventListener(
    popstateEvent,
    onPopstate.bind(ociBasketDrawerManager),
  );
  await loadData();
}

function destroy(): void {
  window.removeEventListener(popstateEvent, onPopstate);
  abortController?.abort();
}

function onResetCount(): void {
  productsCount.value = '1';
}

async function onProductClick(product: IRelatedProductClickEvent): Promise<void> {
  selectedRelatedProductId.value = product.id;
  await init();
}

function onResetProductCount(): void {
  productsCount.value = '1';
  availableOffers.value = false;
  directDeliveryOffers.value = false;
}

watchSubscription.add(
  watch(
    () => productsCount.value,
    () => clearCartOffersState(),
  ),

  watch(
    [() => selectedCalcType.value, () => productsCount.value, () => userBasis.value],
    debounce(async () => {
      try {
        ociBasketDrawerManager?.loading.activate();
        abortController = AbortControllerUtil.createAbortController();
        await onSelectOptionUpdate();
      } catch (error) {
        if (AbortControllerUtil.isRequestAborted(error)) {
          return;
        }
        Notificator.showDetachedNotification('Ошибка расчета предложений');
        console.error(error);
      } finally {
        abortController?.abort();
        ociBasketDrawerManager?.loading.deactivate();
      }
    }, 300),
  ),

  watch(
    [
      availableOffers,
      directDeliveryOffers,
    ],
    async () => await onSelectOptionUpdate(),
    { flush: 'post' },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(async () => await init());

onBeforeMount(() => destroy());
</script>

<style lang="scss" scoped>
@import "styles/base/common/variables";

$root: ".mm-product-view";

#{$root} {
  flex: 1;
  padding: 0 16px;
  overflow-y: unset;
  display: flex;
  flex-direction: column;

  &.no-headline {
    margin-top: 40px;
  }

  &__headline {
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: sticky;
    top: 208px;
    z-index: 5;
    background-color: $text-white;
    display: none;

    &.with-button {
      display: block;
    }

    .btn {
      padding: 8px 16px 8px 10px;

      :deep(svg) {
        margin: 0 8px 0 0;
        width: 20px;
        height: 20px;
      }
    }
  }

  &__content {
    display: flex;

    &__header {
      padding-right: 8px;
      max-width: 756px;

      h3 {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        flex: 1;
      }

      &--light {
        color: $text-light-green;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        height: max-content;
        margin-left: 10px;
      }
    }

    &__actions {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      padding-right: 40px;
      align-items: flex-end;
    }

    &__wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 28px;
      gap: 0 28px;

      .mm-product-view__content__side-characteristic {
        :deep(h2) {
          font-weight: 500;
          margin-bottom: 20px;
          margin-top: 40px;
          color: $text-black;
          font-size: 24px;
        }
      }
    }

    &__side {
      flex: 1;
      position: relative;

      &-images {
        :deep(.mm-images-gallery) {
          .mm-images-gallery__item {
            min-height: 118px;
          }
        }
      }
    }

    :deep(.offers-table-popup) {
      .mm-offer-terms {
        .mm-headline-5 {
          color: $text-white;
        }

        .mm-product-characteristic-wrapper {
          &::before {
            opacity: .2;
          }
        }

        .mm-product-characteristic-label {
          background-color: $text-black;
        }

        .mm-product-characteristic-filler {
          background-image: linear-gradient(to right, $dark-gray 50%, transparent 50%);
        }

        .mm-product-characteristic-value {
          background-color: $text-black;
          color: $text-white;
        }
      }
    }
  }

  &__offers {
    position: relative;
    margin-bottom: 28px;
    min-height: 500px;

    :deep(.mm-table--not-pagination) {
      padding-bottom: 0;
    }
  }

  :deep(.mm-offer-inner) {
    .mm-offer-bottom-wrapper
    .mm-offer-terms
    .mm-offer-term
    .product-characteristic {
      margin-right: 130px;
    }

    .mm-offer-item {
      .mm-product-info {
        margin-right: 191px;

        .product-characteristic--left {
          min-width: 445px;
        }
      }
    }
  }
}

:global(.drawer__wrapper) {
  overflow-y: auto;
}

@media only screen and (max-width: 1599px) and (min-width: 1280px) {
  .mm-product-view {
    .mm-similars-analogs {
      margin-left: 16px;
    }
  }
}

@media only screen and (min-width: 1280px) {
  .mm-similars-analogs {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1279px) {
  .mm-product-view__content__wrapper {
    flex-direction: column;
    margin-top: 28px;
  }

  .mm-product-view {
    padding: 0 4px;

    &__headline {
      padding-left: 20px;
      margin-bottom: 24px;
      top: 180px;
    }

    &__content__side-characteristic {
      margin-bottom: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }

    .mm-product-view__content__header {
      margin-left: 20px;
    }

    &__content__side {
      &.left {
        margin-bottom: 28px;
      }

      &.right {
        :deep(.calculate-inputs) {
          .models-page__right .mm-body-regular-s {
            padding-right: 0 !important;
          }
        }
      }
    }

    &__content {
      &__header {
        flex-direction: column;
      }

      &__actions {
        justify-content: flex-start;
        margin-top: 22px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .mm-product-view {
    &__headline {
      top: 200px;
      z-index: 56;

      &.with-button {
        top: 188px;
      }
    }
  }

  .mm-product-view__content {
    &__header {
      flex-direction: column;

      h3 {
        max-width: unset;
      }
    }

    &__actions {
      justify-content: flex-start;
      margin-top: 22px;
    }

    &__wrapper {
      margin-top: 24px;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 768px) {
  .mm-product-view {
    &__headline {
      top: 178px;
      z-index: 56;

      &.with-button {
        top: 179px;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .mm-product-view {
    &__headline {
      top: 220px;

      &.with-button {
        top: 200px;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .mm-product-view {
    &__headline {
      top: 220px;

      &.with-button {
        top: 232px;
      }
    }
  }
}
</style>
