import { IOciBasketPreErpPosition, IOciBasketPreErpPositionItem, IOciBasketPreOrderItem } from 'oci-models/basket/basket.model';
import { BaseBasketCategory } from 'mp-services/basket-services/base-basket/baseBasketCategory.service';
import { IColumnHeader } from 'shared/models/table.model';
import { OCI_BASKET_CATEGORY_TABLE_ERP_POSITIONS_COLUMNS, OCI_BASKET_CATEGORY_TABLE_PRE_ORDER_ITEMS_COLUMNS } from 'oci-constants/basket/ociBasketCategoryTableColumns.const';
import { IAction } from 'shared/models/action.model';
import { EMoreBasketEntityAction } from 'mp-enums/basket/erpBasketEntityAction.enum';
import { OciBasket } from 'oci-services/basket/ociBasket.service';
import { OciBasketDrawerManager } from './ociBasketDrawerManager.service';

export class OciBasketCategory extends BaseBasketCategory<IOciBasketPreErpPosition | IOciBasketPreOrderItem> {
  public tableData = ref<Array<IOciBasketPreErpPosition | IOciBasketPreOrderItem>>([]);
  public headerList = ref<IColumnHeader[]>([]);

  public tableDataForShow = computed(() => this.filterTableDataForShow());

  private productActions: Array<IAction<EMoreBasketEntityAction>> = [
    { id: EMoreBasketEntityAction.ChangeSupplier, name: 'Сменить поставщика' },
    { id: EMoreBasketEntityAction.SelectAnotherProduct, name: 'Выбрать другой товар' },
    { id: EMoreBasketEntityAction.Delete, name: 'Удалить товар' },
  ];

  private productActionsHandlersMap = new Map<
    EMoreBasketEntityAction,
    (item: IOciBasketPreErpPositionItem, preErpPosition: IOciBasketPreErpPosition) => Promise<void> | void
  >([
    [EMoreBasketEntityAction.ChangeSupplier, this.handleChangeProductSupplier],
    [EMoreBasketEntityAction.SelectAnotherProduct, this.handleSelectAnotherProduct],
    [EMoreBasketEntityAction.Delete, this.handleDeleteProduct],
  ]);

  constructor(
    tableData: Array<IOciBasketPreErpPosition | IOciBasketPreOrderItem>,
    private readonly preErpPositions = true,
    private readonly ociBasket: OciBasket,
    private readonly ociBasketDrawerManager: OciBasketDrawerManager,
  ) {
    super();
    this.tableData.value = tableData;
    this.initHeaderList();
  }

  public getPreErpPositions(): boolean {
    return this.preErpPositions;
  }

  public getProductActions(): Array<IAction<EMoreBasketEntityAction>> {
    return this.ociBasket?.data.value?.ociAddRelatedModels
      ? this.productActions
      : this.productActions.filter((productAction) => productAction.id !== EMoreBasketEntityAction.SelectAnotherProduct);
  }

  public async handleSelectProductAction(
    action: IAction,
    item: IOciBasketPreErpPositionItem,
    preErpPosition: IOciBasketPreErpPosition,
  ): Promise<void> {
    await this.productActionsHandlersMap
      .get(action.id as EMoreBasketEntityAction)?.call(this, item, preErpPosition);
  }

  public handleChangeProductSupplier(
    item: IOciBasketPreErpPositionItem,
    preErpPosition: IOciBasketPreErpPosition,
  ): void {
    this.ociBasketDrawerManager?.openChangeSupplier(preErpPosition, item);
  }

  public handleSelectAnotherProduct(
    item: IOciBasketPreErpPositionItem,
    preErpPosition: IOciBasketPreErpPosition,
  ): void {
    this.ociBasketDrawerManager?.openSelectProduct(preErpPosition, item);
  }

  public async handleDeleteProduct(
    item: IOciBasketPreErpPositionItem,
    preErpPosition?: IOciBasketPreErpPosition,
  ): Promise<void> {
    await this.ociBasket?.deleteProduct(item, !!preErpPosition);
  }

  public async handleDeleteErpPosition(
    erpPosition: IOciBasketPreErpPosition,
  ): Promise<void> {
    await this.ociBasket?.deleteErpPosition(erpPosition);
  }

  public selectProducts(
    preErpPosition: IOciBasketPreErpPosition,
    product?: IOciBasketPreErpPositionItem,
  ): void {
    this.ociBasketDrawerManager.openSelectProduct(
      preErpPosition,
      product,
    );
  }

  public selectNeedSuitableProducts(preErpPosition: IOciBasketPreErpPosition): void {
    this.ociBasketDrawerManager.isNeedSuitableItems.value = true;
    this.ociBasketDrawerManager.openSelectProduct(
      preErpPosition,
      undefined,
    );
  }

  private initHeaderList(): void {
    this.headerList.value = this.getPreErpPositions()
      ? OCI_BASKET_CATEGORY_TABLE_ERP_POSITIONS_COLUMNS
      : OCI_BASKET_CATEGORY_TABLE_PRE_ORDER_ITEMS_COLUMNS;
  }

  private filterTableDataForShow(): Array<IOciBasketPreErpPosition | IOciBasketPreOrderItem> {
    return this.getPreErpPositions()
      ? this.mapDeletedPreErpPositions()
      : this.tableData.value;
  }

  private filterVisiblePreErpPositions(preErpPosition: IOciBasketPreErpPosition): boolean {
    return !preErpPosition.isDeleted
      || !!(preErpPosition.isDeleted
        && this.ociBasket?.deletedErpPositionsIds.value.find((deletedPreErpPositionId) => deletedPreErpPositionId === preErpPosition.id)
      );
  }

  private mapPreErpPositionItems(preErpPosition: IOciBasketPreErpPosition): IOciBasketPreErpPosition {
    return {
      ...preErpPosition,
      items: [
        ...preErpPosition.items,
        ...this.ociBasket.deletedProducts.value
          .filter((item) => item.erpPositionId === preErpPosition.id) as Array<IOciBasketPreErpPositionItem>,
      ],
    };
  }

  private mapDeletedPreErpPositions(): Array<IOciBasketPreErpPosition> {
    return this.tableData.value
      ?.filter((preErpPosition) => this.filterVisiblePreErpPositions(preErpPosition as IOciBasketPreErpPosition))
      .map((preErpPosition) => this.mapPreErpPositionItems(preErpPosition as IOciBasketPreErpPosition));
  }
}
