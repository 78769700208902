import { EErpBasketListProductsView } from 'mp-enums/basket/erpBasketListProductsView.enum';
import { IOciBasketPreErpPosition, IOciBasketPreErpPositionItem } from 'oci-models/basket/basket.model';
import { EErpBasketDrawerView } from 'mp-enums/basket/erpBasketDrawerView.enum';
import { ProviderService } from 'shared/models/providerService.model';
import Loader from 'shared/utils/loaderHelper.util';
import { OCI_BASKET_DRAWER_VIEWS_MAP } from 'oci-constants/basket/ociBasketDrawerViewsMap.const';
import { IProduct } from 'shared/models/product.model';
import { ESortProductDirections } from 'shared/enums/sortDirections.enum';
import { IFilterItem } from 'shared/models/filters.model';

export class OciBasketDrawerManager extends ProviderService {
  protected static readonly serviceName = 'ociBasketDrawerManager';

  public visibleDrawer = ref(false);
  public view = ref(EErpBasketDrawerView.ListProducts);
  public preErpPosition = ref<IOciBasketPreErpPosition | undefined>();
  public product = ref<IOciBasketPreErpPositionItem | IProduct | undefined>();
  public oldProduct = ref<IOciBasketPreErpPositionItem | undefined>();
  public loading = Loader.getReactiveInstance();
  public changeSupplier = ref(false);
  public productsViewTab = ref<EErpBasketListProductsView | undefined>();
  public scrollTopValue?: number;
  public maxItems = ref(0);
  public isNeedSuitableItems = ref(false);

  public viewComponent = computed<Component | undefined>(() => OCI_BASKET_DRAWER_VIEWS_MAP.get(this.view.value));

  /**
   * Фильтры
   */
  public searchTextRequest = ref<string>('');
  /**
   * Сохраненная категория для восстановления при переходе из карточки товара
   * обратно на страницу со всеми товарами
   */
  public savedCategoryId = ref<string | null>(null);
  private sortByTabMap = reactive(new Map<EErpBasketListProductsView, ESortProductDirections>());
  private filtersByTabMap = new Map<EErpBasketListProductsView, Array<IFilterItem>>();
  private itemsCountByTab = reactive(new Map<EErpBasketListProductsView, number>());

  public openDrawer(): void {
    this.visibleDrawer.value = true;

    const elementMainPage = document?.body;
    if (!elementMainPage) {
      return;
    }

    elementMainPage?.classList?.add('no-scroll');
  }

  public closeDrawer(): void {
    if (this.loading.value) {
      return;
    }

    this.visibleDrawer.value = false;
    this.clearState();

    const elementMainPage = document?.body;
    if (!elementMainPage) {
      return;
    }

    elementMainPage?.classList?.remove('no-scroll');
  }

  public openChangeSupplier(
    preErpPosition: IOciBasketPreErpPosition,
    product: IOciBasketPreErpPositionItem,
  ): void {
    this.changeSupplier.value = true;
    this.preErpPosition.value = preErpPosition;
    this.product.value = product;
    this.oldProduct.value = product;
    this.view.value = EErpBasketDrawerView.Product;
    this.calculateMaxItems();
    this.openDrawer();
  }

  public openSelectProduct(
    preErpPosition: IOciBasketPreErpPosition,
    product?: IOciBasketPreErpPositionItem,
  ): void {
    this.changeSupplier.value = false;
    this.preErpPosition.value = preErpPosition;
    this.oldProduct.value = product;
    this.product.value = product;
    this.calculateMaxItems();
    this.openDrawer();
  }

  public goBackToProducts(): void {
    if (this.loading.value) {
      return;
    }

    this.product.value = undefined;
    this.view.value = EErpBasketDrawerView.ListProducts;
  }

  public goToProduct(product: IProduct): void {
    this.product.value = product;
    nextTick(() => this.view.value = EErpBasketDrawerView.Product);
  }

  public setCurrentTab(tab: EErpBasketListProductsView) {
    this.productsViewTab.value = tab;
  }

  public setSortByCurrentTab(sort: ESortProductDirections) {
    this.sortByTabMap.set(this.productsViewTab.value ?? EErpBasketListProductsView.CategorySearch, sort);
  }

  public getSortByCurrentTab() {
    return this.sortByTabMap.get(this.productsViewTab.value ?? EErpBasketListProductsView.CategorySearch);
  }

  public setFiltersByCurrentTab(filters: Array<IFilterItem>) {
    // Сделано через ??, для кейса когда нету табов для отображения на странице
    // при поиске по категориям
    this.filtersByTabMap.set(this.productsViewTab.value ?? EErpBasketListProductsView.CategorySearch, filters);
  }

  public getFiltersByTab(tab: EErpBasketListProductsView | undefined) {
    if (tab) {
      return this.filtersByTabMap.get(tab);
    }
  }

  public getFiltersByCurrentTab() {
    return this.getFiltersByTab(this.productsViewTab.value ?? EErpBasketListProductsView.CategorySearch);
  }

  public setItemsCountByTab(tab: EErpBasketListProductsView, itemsCount: number) {
    this.itemsCountByTab.set(tab, itemsCount);
  }

  public getItemsCountByTab(tab: EErpBasketListProductsView | undefined) {
    if (tab) {
      return this.itemsCountByTab.get(tab);
    }
  }

  private clearState(): void {
    this.view.value = EErpBasketDrawerView.ListProducts;
    [
      this.preErpPosition,
      this.product,
      this.oldProduct,
      this.productsViewTab,
    ].forEach((item) => item.value = undefined);
    this.changeSupplier.value = false;
    this.isNeedSuitableItems.value = false;
    [
      this.itemsCountByTab,
      this.filtersByTabMap,
      this.sortByTabMap,
    ].forEach((item) => item.clear());
    this.searchTextRequest.value = '';
  }

  private calculateMaxItems(): void {
    const product = (this.oldProduct.value as IOciBasketPreErpPositionItem);
    const needOtherProductCounts = (this.preErpPosition.value?.items || [])
      .filter((item) => !item.deleted)
      .reduce((acc, item) => acc + item.quantity, 0) - (product?.quantity || 0);

    this.maxItems.value = (this.preErpPosition.value?.quantity || 0) <= (product?.quantity || 0)
      ? this.preErpPosition.value?.quantity || 0
      : (this.preErpPosition.value?.quantity || 0) - needOtherProductCounts;

    this.maxItems.value = this.maxItems.value < 0
      ? 0
      : this.maxItems.value;
  }
}
