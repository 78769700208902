import { BaseBasket } from 'mp-services/basket-services/base-basket/baseBasket.service';
import { OciBasketCategory } from './ociBasketCategory.service';
import { OciOrdersApiService } from 'oci-services/api/ociOrdersApi.service';
import { IPreOrderItem } from 'oci-models/basket/preOrderItem.model';
import Notificator from 'shared/services/notificator.service';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { EBasketStatus } from 'mp-enums/basket/basketStatus.enum';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import {
  IOciBasket,
  IOciBasketPreErpPosition,
  TOciBasketItem,
  IOciBasketPreErpPositionItem,
  IOciBasketPreOrderItem,
} from 'oci-models/basket/basket.model';
import { basketLoaderMap } from 'mp-constants/basket/basket.const';
import { EBasketActionLoaderType } from 'mp-enums/basket/basketLoaderInitiator.enum';
import { OciBasketDrawerManager } from './ociBasketDrawerManager.service';
import Modal from 'shared/components/modals/Modal.vue';
import { ObserverManager } from 'shared/services/observer-manager/observerManager.service';
import { Observer } from 'shared/services/observer-manager/observer.service';
import { IOciReturnOrderItem } from 'oci-models/services/api/ociReturnOrderItem.model';
import { ApiHelper } from 'shared/services/api/apiHelper.service';
import { IOciBasketStatus } from 'oci-models/services/api/ociBasketStatus.model';
import { FetchError } from 'ofetch';
import { IUseOciManager } from 'oci-models/composables/useOciManager.model';
import { OciBasketCounterService } from './ociBasketCounter.service';
import { IOciReplaceItemData } from 'oci-models/services/api/ociReplaceItemData.model';
import { TranslateOptions } from '@nuxtjs/i18n/dist/runtime/composables';
import { CONFLICT_ERROR_CODE } from 'shared/constants/error.const';
import { EErrorType } from 'shared/enums/errorType.enum';
import { EBasketWarningType } from 'mp-enums/basket/basketWarningType.enum';
import { IOciReturnOrderNameTemplateErrorItem } from 'oci-models/services/api/ociReturnOrderErrors.model';

export class OciBasket extends BaseBasket<IOciBasket> {
  protected static readonly basketPagePath = '/oci/basket';

  public categoryList = shallowRef<Array<OciBasketCategory>>([]);
  public deletedErpPositionsIds = ref<Array<number>>([]);
  public deletedProducts = ref<Array<TOciBasketItem>>([]);

  private saveBasketObserver = new ObserverManager<Array<IOciReturnOrderItem>>();
  private timerCheckStatus: ReturnType<typeof setTimeout> | null = null;

  private readonly timeoutCheckStatus = 3000;

  private readonly fetchErrorHandlersMap = new Map<EErrorType, (fetchError: FetchError) => void>([
    [EErrorType.NameTemplateError, this.handleNameTemplateError],
  ]);

  constructor(
    private readonly ociManager: IUseOciManager,
    private readonly ociBasketCounterService: OciBasketCounterService,
    translateFn: (key: string, plural?: number, options?: TranslateOptions<string>) => string,
    private readonly ociBasketDrawerManager = new OciBasketDrawerManager(),
  ) {
    super(translateFn);
    this.initOrderInfo();
    provide(OciBasketDrawerManager.getServiceName(), this.ociBasketDrawerManager);
    this.setBasketStatus(EBasketStatus.Loading);
  }

  public getOciManager(): IUseOciManager {
    return this.ociManager;
  }

  public subscriveSaveBasket(callback: (returnOrder?: Array<IOciReturnOrderItem>) => void): Observer<Array<IOciReturnOrderItem>> {
    return this.saveBasketObserver.subscribe(callback);
  }

  public unsubscriveSaveBasket(observer: Observer<Array<IOciReturnOrderItem>>): void {
    this.saveBasketObserver.unsubscribe(observer);
  }

  public getBasisId(): number | undefined {
    return this.data.value?.basisId;
  }

  public async checkStatus(): Promise<void> {
    if (!this.userStore.sessionId || this.isLoading.value) {
      return;
    }

    try {
      const basketStatus = await OciOrdersApiService.getBasketStatus(this.userStore.sessionId);
      if (!basketStatus.isAutoSelectionActive) {
        return this.onBasketCompleted();
      }

      this.timerCheckStatus = setTimeout(
        async () => await this.checkStatus(),
        this.timeoutCheckStatus,
      );
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        'oci-basket',
        undefined,
        {
          extra: {
            sessionId: this.userStore.sessionId,
          },
        },
      );
    }
  }

  public async initLongPooling(): Promise<void> {
    if (!this.userStore.sessionId) {
      return;
    }

    this.clearTimerCheckStatus();
    this.isLoading.activate();
    this.setLoadBasketLoaderInfo();
    this.basketStatus.value = EBasketStatus.AutoSelectionInProgress;
    this.initDataForSkeletonLoading();
    try {
      const basketStatus = await ApiHelper.retryRequest<IOciBasketStatus, FetchError>(
        OciOrdersApiService.getBasketStatus.bind(OciOrdersApiService),
        [[this.userStore.sessionId], { method: 'GET' }],
        {
          retryTimeout: 2000,
          isNeedRetry: (res) => res?.isAutoSelectionActive,
        },
      );

      if (!basketStatus.isAutoSelectionActive) {
        return await this.loadBasket();
      }

      this.basketStatus.value = EBasketStatus.Empty;
    } catch (error) {
      this.basketStatus.value = EBasketStatus.Empty;
      Notificator.showDetachedNotification('Ошибка при формировании заказа');
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: {
          sessionId: this.userStore.sessionId,
          error,
        },
      });
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async loadBasket(): Promise<void> {
    if (!this.userStore?.sessionId) {
      return;
    }

    this.isLoading.activate();
    this.setLoadBasketLoaderInfo();

    try {
      await this.onLoadBasket(
        await OciOrdersApiService.getBasket(this.userStore?.sessionId),
      );
    } catch (error) {
      this.basketStatus.value = EBasketStatus.Empty;
      clientSentry.captureServiceException(error, OciBasket.getServiceName());
      Notificator.showDetachedNotification('Ошибка загрузки корзины');
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async saveBasket(): Promise<void> {
    if (!this.userStore.sessionId || this.isLoading.value) {
      return;
    }

    if (!await this.isContinueSaveWithMoreProfitableProducts()) {
      return;
    }

    this.isLoading.activate();
    this.setSaveBasketLoaderInfo();

    try {
      const response = await OciOrdersApiService.returnOrderV2(
          this.userStore?.sessionId,
          `${location.protocol}//${location.hostname}`,
      );
      this.saveBasketObserver.notify(response);
    } catch (error) {
      if (error instanceof FetchError
        && this.handleSaveBasketError(error)
      ) {
        return;
      }

      Notificator.showDetachedNotification('Ошибка при обновлении данных в потребности');
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: {
          data: this.data,
          sessionId: this.userStore.sessionId,
          error,
        },
      });
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async addToBasket(preOrderItem: IPreOrderItem): Promise<void> {
    if (!this.userStore.sessionId) {
      return;
    }

    try {
      await this.onLoadBasket(
        await OciOrdersApiService.addItemToPreOrder(
          this.userStore.sessionId,
          preOrderItem,
        ),
      );
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket');
      Notificator.showDetachedNotification('Ошибка при добавлении товара в предварительную корзину');
      throw error;
    }
  }

  public initOrderInfo(): void {
    this.orderInfo = computed<string>(
      () =>
        `${this.translateFn(ERuLocales.Item, this.data.value?.itemCount ?? 0)}${this.basesManager?.base
          ? ` с доставкой в ${this.basesManager.base}`
          : ''}`,
    );
  }

  public async clear(): Promise<void> {
    if (!this.userStore.sessionId || this.isLoading.value) {
      return;
    }

    this.isLoading.activate();
    this.setClearBasketLoaderInfo();

    try {
      if (await this.showConfirmModal(
        'Вы уверены, что хотите очистить корзину?',
        'Чтобы очистить корзину, подтвердите действие.',
        'Очистить корзину',
      )) {
        await this.onLoadBasket(
          await OciOrdersApiService.deleteItems(this.userStore.sessionId),
        );
      }
    } catch (error) {
      Notificator.showDetachedNotification('Ошибка при очистке корзины');
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async deleteProduct(item: TOciBasketItem, saveToState = false): Promise<void> {
    if (this.isLoading.value || !this.userStore.sessionId) {
      return;
    }

    this.loaderInfo.value = basketLoaderMap.get(EBasketActionLoaderType.Delete);
    this.isLoading.activate();

    try {
      if (await this.showConfirmModal(
        'Вы точно хотите удалить товар из заказа?',
        'Чтобы удалить товар, подтвердите действие.',
      )) {
        const ociBasketData = await OciOrdersApiService.deleteItem(this.userStore.sessionId, item.id);

        if (saveToState) {
          this.saveProduct(item);
        }

        await this.onLoadBasket(ociBasketData);
      }
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: { item },
      });
      Notificator.showDetachedNotification('Ошибка при удалении товара');
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async deleteErpPosition(erpPosition: IOciBasketPreErpPosition): Promise<void> {
    if (this.isLoading.value || !this.userStore.sessionId) {
      return;
    }

    this.loaderInfo.value = basketLoaderMap.get(EBasketActionLoaderType.Delete);
    this.isLoading.activate();

    try {
      if (await this.showConfirmModal(
        'Вы точно хотите удалить потребность из заказа?',
        'Чтобы удалить потребность, подтвердите действие.',
      )) {
        const ociBasketData = await OciOrdersApiService.deleteErpPosition(this.userStore.sessionId, erpPosition.id);
        this.saveDeletedErpPosition(erpPosition);
        await this.onLoadBasket(ociBasketData);
      }
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: { erpPosition },
      });
      Notificator.showDetachedNotification('Ошибка при удалении потребности');
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async updateProduct(itemId: number, item: IPreOrderItem): Promise<void> {
    if (this.isLoading.value || !this.userStore.sessionId) {
      return;
    }

    this.isLoading.activate();

    try {
      await this.onLoadBasket(await OciOrdersApiService.updateItem(this.userStore.sessionId, itemId, item));
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: {
          itemId,
          item,
        },
      });
      throw error;
    } finally {
      this.isLoading.deactivate();
    }
  }

  public async replaceProduct(itemId: number, data: IOciReplaceItemData): Promise<void> {
    if (this.isLoading.value || !this.userStore.sessionId) {
      return;
    }

    this.setReplaceProductLoaderInfo();
    this.isLoading.activate();

    try {
      await this.onLoadBasket(await OciOrdersApiService.replaceItem(
        this.userStore.sessionId,
        itemId,
        data,
      ));
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: {
          itemId,
          data,
        },
      });
      throw error;
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public async restorePreErpPosition(preErpPosition: IOciBasketPreErpPosition): Promise<void> {
    if (this.isLoading.value || !this.userStore.sessionId) {
      return;
    }

    this.loaderInfo.value = basketLoaderMap.get(EBasketActionLoaderType.Restore);
    this.isLoading.activate();

    try {
      const ociBasketData = await OciOrdersApiService.undeleteErpPosition(
        this.userStore.sessionId,
        preErpPosition.id,
      );
      this.deletePreErpPositionFromState(preErpPosition);
      await this.onLoadBasket(ociBasketData);
    } catch (error) {
      clientSentry.captureServiceException(error, 'oci-basket', undefined, {
        extra: { preErpPosition },
      });
      Notificator.showDetachedNotification('Ошибка при восстановлении потребности');
    } finally {
      this.isLoading.deactivate();
      this.clearLoaderInfo();
    }
  }

  public deleteProductFromState(product: IOciBasketPreErpPositionItem): void {
    this.deletedProducts.value = this.deletedProducts.value
      .filter((item) => item.id !== product.id);
  }

  public deletePreErpPositionFromState(
    preErpPosition: IOciBasketPreErpPosition,
  ): void {
    this.deletedErpPositionsIds.value = this.deletedErpPositionsIds.value
      .filter(
        (deletedErpPositionId) => deletedErpPositionId !== preErpPosition.id,
      );

    if (this.data.value) {
      this.onLoadBasket(this.data.value);
    }
  }

  private setLoadBasketLoaderInfo(): void {
    this.loaderInfo.value = {
      text: 'Идет подбор товаров...',
      caption: 'Пожалуйста, дождитесь окончания процесса',
      withBackground: true,
    };
  }

  private setSaveBasketLoaderInfo(): void {
    this.loaderInfo.value = {
      text: 'Идет обновление данных в потребности...',
      caption: 'Пожалуйста, дождитесь окончания процесса',
      withBackground: true,
    };
  }

  private setClearBasketLoaderInfo(): void {
    this.loaderInfo.value = {
      text: 'Идет очистка корзины...',
      caption: 'Пожалуйста, дождитесь окончания процесса',
      withBackground: true,
    };
  }

  private setReplaceProductLoaderInfo(): void {
    this.loaderInfo.value = {
      text: 'Идет замена товара...',
      caption: 'Пожалуйста, дождитесь окончания процесса',
      withBackground: true,
    };
  }

  private clearLoaderInfo(): void {
    this.loaderInfo.value = undefined;
  }

  public clearState(): void {
    this.isLoading.activate();
    this.basketStatus.value = EBasketStatus.Loading;
    this.categoryList.value = [];
    this.isLoading.deactivate();
  }

  public getSumString(): string {
    return BaseBasket.getCorrectedSumValue(this.data.value?.costNetWithVat ?? 0);
  }

  public isEmptyItems(): boolean {
    return !this.data.value?.preErpPositions?.length
      && !this.data.value?.preOrderItems?.length;
  }

  public clearTimerCheckStatus(): void {
    if (this.timerCheckStatus) {
      clearTimeout(this.timerCheckStatus);
    }
    this.timerCheckStatus = null;
  }

  private initDataForSkeletonLoading(): void {
    /*
     * Создаем пустые данные для отображения skeleton во время загрузки корзины.
     * */
    this.categoryList.value = [
      new OciBasketCategory(
        Array.from(Array(10), () => ({ items: [1] })),
        true,
        this,
        this.ociBasketDrawerManager,
      ),
      new OciBasketCategory(
        Array.from(Array(10)),
        false,
        this,
        this.ociBasketDrawerManager,
      ),
    ];
  }

  private showConfirmModal(
    titleText: string,
    contentText: string,
    okButtonText = 'Удалить',
    cancelButtonText = 'Отменить',
  ): Promise<boolean> {
    const modalName = 'confirmOciActionModal';
    const modalManager = this.modalManager;
    return new Promise((resolve) => modalManager
      ?.show({
        bind: {
          zIndex: '10000 !important',
          name: modalName,
          contentText,
          titleText,
          cancelButtonText,
          okButtonText,
          classes: 'modal fixed-bottom-modal',
        },
        component: Modal,
        on: {
          ok() {
            resolve(true);
            modalManager.hide(modalName);
          },
          cancel() {
            resolve(false);
            modalManager.hide(modalName);
          },
          close() {
            resolve(false);
            modalManager.hide(modalName);
          },
        },
      }),
    );
  }

  private async onLoadBasket(ociBasketData: IOciBasket): Promise<void> {
    this.data.value = ociBasketData;
    this.initCategoryList(ociBasketData);
    this.setBasketStatus(this.getBasketStatus(ociBasketData));
    this.ociBasketCounterService.setCount(ociBasketData.itemCount);
  }

  private initCategoryList(ociBasketData: IOciBasket): void {
    const newCategoryList: Array<OciBasketCategory> = [];
    if (!this.isPreErpPositionsEmpty(ociBasketData?.preErpPositions)) {
      newCategoryList.push(new OciBasketCategory(
        ociBasketData.preErpPositions,
        true,
        this,
        this.ociBasketDrawerManager,
      ));
    }

    newCategoryList.push(new OciBasketCategory(
      ociBasketData.preOrderItems,
      false,
      this,
      this.ociBasketDrawerManager,
    ));

    this.categoryList.value = newCategoryList;
  }

  private getBasketStatus(ociBasketData: IOciBasket): EBasketStatus {
    if (this.isBasketEmpty(ociBasketData)) {
      return EBasketStatus.Empty;
    }

    return EBasketStatus.UpdateDataInNeed;
  }

  private isBasketEmpty(ociBasketData: IOciBasket): boolean {
    return this.isPreErpPositionsEmpty(ociBasketData?.preErpPositions)
      && !ociBasketData?.preOrderItems?.length;
  }

  private isPreErpPositionsEmpty(preErpPositions: Array<IOciBasketPreErpPosition>): boolean {
    return !preErpPositions?.length
      || this.existDeletedPreErpPositionsInState(preErpPositions);
  }

  private existDeletedPreErpPositionsInState(preErpPositions: Array<IOciBasketPreErpPosition>): boolean {
    return !preErpPositions?.some(
      (preErpPosition) => !preErpPosition?.isDeleted
        || (preErpPosition?.isDeleted && this.deletedErpPositionsIds.value.find(
          (deletedPreErpPosiion) => deletedPreErpPosiion === preErpPosition.id)
        ),
    );
  }

  private saveDeletedErpPosition(erpPosition: IOciBasketPreErpPosition): void {
    this.deletedErpPositionsIds.value.push(erpPosition.id);
  }

  private saveProduct(item: TOciBasketItem): void {
    if (!item) {
      return;
    }

    item.deleted = true;
    this.deletedProducts.value.push(item as IOciBasketPreErpPositionItem);
  }

  private async onBasketCompleted(): Promise<void> {
    this.clearTimerCheckStatus();
    await this.ociBasketCounterService.loadCounter();
  }

  private async isContinueSaveWithMoreProfitableProducts(): Promise<boolean> {
    const existsMoreProfitableProduct = this.data.value?.preErpPositions
      .some((preErpPosition) => (
        preErpPosition.items?.some((item) => item.moreProfitableProduct)
      ));

    if (!existsMoreProfitableProduct) {
      return true;
    }

    return await this.showConfirmModal(
      'Подтверждение заказа',
      'В заказе есть товары, по которым есть более выгодные предложения. Подтвердите заказ или вернитесь в корзину для замены товаров на более выгодные.',
      'Подтвердить заказ',
      'Вернуться в корзину',
    );
  }

  private handleSaveBasketError(fetchError: FetchError): boolean {
    if (this.fetchErrorHandlersMap.has(fetchError?.data?.data?.code)) {
      this.fetchErrorHandlersMap.get(fetchError.data.data.code)?.call(this, fetchError);
      return true;
    }

    return false;
  }

  private handleNameTemplateError(fetchError: FetchError): void {
    if (fetchError.status !== CONFLICT_ERROR_CODE
      || !this.data.value
    ) {
      return;
    }

    const errors = fetchError?.data?.data?.ctx?.errors as Array<IOciReturnOrderNameTemplateErrorItem>;

    this.onLoadBasket({
      ...this.data.value,
      preOrderItems: this.data.value.preOrderItems.map(
        (preOrderItem) => this.setToItemNameTemplateError(preOrderItem, errors) as IOciBasketPreOrderItem,
      ),
      preErpPositions: this.data.value.preErpPositions.map(
        (preErpPosition) => ({
          ...preErpPosition,
          items: preErpPosition.items.map(
            (preErpPositionItem) => this.setToItemNameTemplateError(preErpPositionItem, errors) as IOciBasketPreErpPositionItem,
          ),
        }),
      ),
    });
  }

  private setToItemNameTemplateError(
    item: IOciBasketPreErpPositionItem | IOciBasketPreOrderItem,
    errors: Array<IOciReturnOrderNameTemplateErrorItem>,
  ): IOciBasketPreErpPositionItem | IOciBasketPreOrderItem {
    if (!errors?.some?.((error) => error?.modelId === item.modelId)
      || item?.warnings?.some?.((warning) => warning?.type === EBasketWarningType.OciNameTemplateError)
    ) {
      return item;
    }

    return {
      ...item,
      warnings: [
        ...(item.warnings || []),
        {
          type: EBasketWarningType.OciNameTemplateError,
        },
      ],
    };

  }
}
